import {graphql, navigate, useStaticQuery} from 'gatsby';
import React from 'react';

import {MongoDestination} from '@travelfreedom/shared';

import {buildMapDataFromRankingGradient, CountryPill, Layout, SEO, WorldMap} from '../components';
import {Destination, Knowledge} from '../library/common';

export const DestinationPage = (): JSX.Element => {
  const {allMongodbTravelfreedomDestinations} = useStaticQuery(graphql`
    query {
      allMongodbTravelfreedomDestinations(sort: {fields: name}) {
        nodes {
          globalCode
          name
        }
      }
    }
  `);

  const goToCode = (isoCode: string): void => {
    const isoPath = Knowledge.isoCodeToPath(isoCode);
    navigate(`/destination/${isoPath}`);
  };

  const destinations: Destination.Data[] = allMongodbTravelfreedomDestinations.nodes.map(
    (node: MongoDestination) => Destination.fromLeanJson(node)
  );

  return (
    <Layout>
      <SEO title="Destinations" pathname="/destination" />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">Destinations</h1>

      <div className="bg-white my-2 max-w-7xl mx-auto">
        <WorldMap
          worldMapData={buildMapDataFromRankingGradient(destinations)}
          onTerritoryClick={goToCode}
        />
      </div>

      <div className="my-2 max-w-7xl mx-auto">
        {destinations.map((destination) => (
          <CountryPill
            key={destination.globalCode}
            name={destination.name}
            code={destination.code}
            href={Destination.hrefPath(destination)}
          />
        ))}
      </div>
    </Layout>
  );
};

export default DestinationPage;
